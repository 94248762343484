import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Project, ProjectList } from './project.model';
import { Pagination } from 'app/core/api/pagination.type';
import { Employee } from '../organization/organization.model';

@Injectable({
    providedIn: 'root'
})
export class ProjectService {

    private _projects: BehaviorSubject<Project[] | null> = new BehaviorSubject(null);
    private _project: BehaviorSubject<Project | null> = new BehaviorSubject(null);
    errorMessage: string = "Something went wrong ";
    pagination: Pagination;
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for projects
     */
    get projects$(): Observable<Project[]> {
        return this._projects.asObservable();
    }

    get project$(): Observable<Project> {
        return this._project.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get project list 
     */
    getProjects(page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string, noPagination: boolean = false, factory = null): Observable<ProjectList> {
        if (sortDirection == 'desc')
            sort = '-' + sort;
        let params = {
            page: page ? ++page : 1,
            ordering: sort ? sort : '',
            search: query ? query : '',
            page_size: batch_size ? batch_size : 10,
            no_pagination: noPagination == true ? 1 : 0
        }

        if (factory != null)
            params['factory'] = factory

        return this._httpClient.get<ProjectList>(`${BASE_URL}project/`, {
            params: params
        }).pipe(
            tap((response) => {
                if (response.data?.length == 0) {
                    this.errorMessage = "There are no projects to display!"
                }
                this._projects.next(response.data);
                this.pagination = {
                    page: --response.page,
                    total_count: response.total_count
                };
            })
        );
    }

    getProjectsForDropdown(query: string) {
        return this._httpClient.get<ProjectList>(`${BASE_URL}project/`, {
            params: {
                search: query ? query : '',
                no_pagination: true
            }
        })
    }

    /**
     * Delete Project
     * @param projectId
     */
    deleteProject(projectId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}project/${projectId}/`).pipe(
            tap((res: any) => {
                this.pagination.total_count -= 1
                this._projects.next(this._projects.value.filter(project => project.id !== projectId));
            }, err => of([]))
        )
    }

    createProject(credentials): Observable<any> {
        return this._httpClient.post(`${BASE_URL}project/`, credentials);
    }

    updateProject(credentials, projectId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}project/${projectId}/`, credentials).pipe(
            tap((response) => {
                this._project.next(response.data);
            })
        );
    }

    getStatusChoices(): Observable<string[]> {
        return this._httpClient.get<string[]>(`${BASE_URL}project/status_choices/`)
    }

    getProjectDetails(projectId): Observable<any> {
        return this._httpClient.get(`${BASE_URL}project/${projectId}/`).pipe(
            tap((response) => {
                this._project.next(response);
            })
        );
    }

    getActionParties(projectId): Observable<Employee[]> {
        return this._httpClient.get<Employee[]>(`${BASE_URL}project/${projectId}/action_party/`);
    }
}
